import React from "react";
import { Link } from "react-router-dom";
import FooterBottom from "./FooterBottom";
import Newsletter from "../../Common/Newsletter";
import footerLogo1 from "../../../assets/img/logo/logo.png";

const Footer = (props) => {
  const { footerLogo, footerClass } = props;
  return (
    <footer className={footerClass ? footerClass : "rs-footer"}>
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-xl-3 col-lg-3 col-md-4">
              <div className="footer-logo">
                <Link to="/">
                  <img
                    src={footerLogo ? footerLogo : footerLogo1}
                    alt="Company Logo"
                  />
                </Link>
              </div>
              <div className="textwidget pb-30">
                <p>
                  It is a premier software development Organization, delivering
                  client-server solutions, standalone applications, Data
                  conversion, Web-based software solutions, and website
                  designing, which are Promoted by our experienced, young, and
                  self-motivated developers.
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 pl-44 md-pl-14 md-mb-30">
              <h3 className="widget-title">IT Services</h3>
              <ul className="site-map">
                <li>
                  <Link to="/service/software-development">
                    Software Development
                  </Link>
                </li>
                <li>
                  <Link to="/service/Web-development">Web Development</Link>
                </li>
                <li>
                  <Link to="/service/mobile-app-development">
                    Mobile App Development
                  </Link>
                </li>
                <li>
                  <Link to="/service/api-integration">API Integration</Link>
                </li>
                <li>
                  <Link to="/service/digital-marketing">Digital Marketing</Link>
                </li>                
                <li>
                  <Link to="/service/e-commerce-development">E-Commerce Development</Link>
                </li>
              </ul>
              <ul className="footer-social md-mb-30">
                <li>
                  <a
                    href="https://www.facebook.com/steemasoftwarepvtltd/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-facebook-f"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://twitter.com/SoftwareSteema"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-twitter"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.linkedin.com/company/steemasoftware/mycompany/?viewAsMember=true"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-linkedin"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.youtube.com/channel/UCTFUh9vj9IZsEvIrAxuk-Vw?view_as=subscriber"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-youtube"></i>
                  </a>
                </li>
                <li>
                  <a
                    href="https://github.com/Steema-Software"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <i className="fa fa-github"></i>
                  </a>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12 md-mb-30">
              <h3 className="widget-title">Contact Info</h3>
              <ul className="address-widget">
                <li>
                  <i className="flaticon-location"></i>
                  <div className="desc">
                    Smart Avenue, 80 Feet Road, Michael Palya, Indiranagar, Bengaluru,
                    Karnataka 560038
                  </div>
                  {/* <div className="desc">
                  <i className="fa fa-map-o mt-5"></i>Chandralok Complex, S.D. Road, Paradise, Secunderabad,
                    Hyderabad-500003
                  </div> */}
                </li>
                <li>
                  <i className="flaticon-call"></i>
                  <div className="desc">
                    <Link to="tel:08789400649" rel="nofollow">+91-878-940-0649</Link><br />
                    <Link to="tel:06299864682" rel="nofollow">+91-629-986-4682</Link>
                  </div>
                </li>
                <li>
                  <i className="flaticon-email"></i>
                  <div className="desc">
                    <Link to="mailto:sales@steemasoftware.com" rel="nofollow">
                      sales@steemasoftware.com
                    </Link>
                  </div>
                </li>
              </ul>
            </div>
            <div className="col-lg-3 col-md-12 col-sm-12">
              <h3 className="widget-title">Newsletter</h3>
              <p className="widget-desc">
                Get fabulous deals and exiting offers straight to your inbox.
                <br /> <br />
                Subscribe now to come one more step closure to us!
              </p>
              <div>
                {/* newsletter-area-start */}
                <Newsletter />
                {/* newsletter-area-end */}

                <ul className="address-widget">
                  <li>
                    <i className="flaticon-clock"></i>
                    <div className="desc">
                      Opening Hours: 09:00 AM to 06:00 PM (Monday to Saturday)
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterBottom />
    </footer>
  );
};

export default Footer;
