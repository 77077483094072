import React from "react";
import { Link } from "react-router-dom";
import canvasLogo from "../../../assets/img/logo/logo.png";

const CanvasMenu = () => {
  const canvasMenuRemove = () => {
    document.body.classList.remove("nav-expanded");
  };

  return (
    <React.Fragment>
      <nav className="right_menu_togle hidden-md">
        <div className="close-btn">
          <div className="nav-link">
            <a
              onClick={canvasMenuRemove}
              id="nav-close"
              className="humburger nav-expander"
              href="/#"
            >
              <span className="dot1"></span>
              <span className="dot2"></span>
              <span className="dot3"></span>
              <span className="dot4"></span>
              <span className="dot5"></span>
              <span className="dot6"></span>
              <span className="dot7"></span>
              <span className="dot8"></span>
              <span className="dot9"></span>
            </a>
          </div>
        </div>
        <div className="canvas-logo">
          <Link to="/">
            <img src={canvasLogo} alt="logo" />
          </Link>
        </div>
        <div className="offcanvas-text">
          <p>
          STEEMA SOFTWARE combines world-class technologies and services with its technical expertise. Our ability to bundle services brings integrated sourcing to our clients, ensuring that they will get the right mix of technology and services appropriate to their requirements.
          </p>
        </div>
        <div className="canvas-contact">
          <div className="address-area">
            <div className="address-list">
              <div className="info-icon">
                <i className="fa fa-map-marker"></i>
              </div>
              <div className="info-content">
                <h4 className="title">&nbsp;&nbsp;&nbsp;Address</h4>
                {/* <em>&nbsp;&nbsp;&nbsp;<i className="fa fa-chevron-circle-right"></i>&nbsp;&nbsp;Paradise, Secunderabad, Hyderabad-500003</em> */}
                <em>&nbsp;&nbsp;&nbsp;<i className="fa fa-chevron-circle-right"></i>&nbsp;&nbsp;Indiranagar, Bengaluru, Karnataka 560038</em>
              </div>
            </div>
            <div className="address-list">
              <div className="info-icon">
                <i className="fa fa-envelope-open"></i>
              </div>
              <div className="info-content">
                <h4 className="title">Email</h4>
                <em>
                  <a href="mailto:sales@steemasoftware.com" rel="nofollow">sales@steemasoftware.com</a>
                </em>
              </div>
            </div>
            <div className="address-list">
              <div className="info-icon">
                <i className="fa fa-phone"></i>
              </div>
              <div className="info-content">
                <h4 className="title">&nbsp;&nbsp;Phone</h4>
                <em>&nbsp;&nbsp;+91-8789400649 / 6299864682</em>
              </div>
            </div>
          </div>
          <ul className="social">
            <li>
              <a href="https://www.facebook.com/steemasoftwarepvtltd/" target="_blank" rel="noreferrer">
                <i className="fa fa-facebook-f"></i>
              </a>
            </li>
            <li>
              <a href="https://twitter.com/SoftwareSteema" target="_blank" rel="noreferrer">
                <i className="fa fa-twitter"></i>
              </a>
            </li>
            <li>
              <a href="https://www.linkedin.com/company/steemasoftware/mycompany/?viewAsMember=true" target="_blank" rel="noreferrer">
                <i className="fa fa-linkedin"></i>
              </a>
            </li>
            <li>
              <a href="https://www.youtube.com/channel/UCTFUh9vj9IZsEvIrAxuk-Vw?view_as=subscriber" target="_blank" rel="noreferrer">
                <i className="fa fa-youtube"></i>
              </a>
            </li>
            <li>
              <a href="https://github.com/Steema-Software" target="_blank" rel="noreferrer">
                <i className="fa fa-github"></i>
              </a>
            </li>
          </ul>
        </div>
      </nav>
    </React.Fragment>
  );
};

export default CanvasMenu;
