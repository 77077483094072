import React from "react";
import SectionTitle from "../Common/SectionTitle";





const ContactInfo = (props) => {
  const { contactBoxClass } = props;
  return (
    <div className={contactBoxClass ? contactBoxClass : "contact-box"}>
      <SectionTitle
        sectionClass="sec-title mb-44"
        subtitleClass="sub-text new-text white-color"
        subtitle="Let's Talk"
        titleClass="title white-color"
        title="Speak With Expert Engineers."
      />
      <div className="address-box mb-24">
        <div className="address-icon">
          <i className="fa fa-phone"></i>
        </div>
        <div className="address-text">
          <span className="label">Phone:</span>
          <a href="tel:8789400649" rel="nofollow">+91-878-940-0649</a><br />
          <a href="tel:6299864682" rel="nofollow">+91-629-986-4682</a>
        </div>
      </div>
      <div className="address-box mb-24">
        <div className="address-icon">
          <i className="fa fa-home"></i>
        </div>
        <div className="address-text">
          <span className="label">Email:</span>
          <a href="mailto:sales@steemasoftware.com" rel="nofollow">sales@steemasoftware.com</a>
        </div>
      </div>
      <div className="address-box">
        <div className="address-icon">
          <i className="fa fa-map-marker"></i>
        </div>
        <div className="address-text">
          {/* <span className="label">Address 1:</span>
          <div className="desc">
            Cabin No-8, A&amp;B Block, 5th floor, Chandralok Complex, S.D. Road,
            Paradise, Secunderabad, Hyderabad, Telangana 500003
          </div>
          <br /> */}
          <span className="label">Address:</span>
          <div className="desc">
            Smart Avenue, Fourth Floor, 28/A, 80 Feet Road,
            Michael Palya, Indiranagar, Bengaluru, Karnataka 560038
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactInfo;
